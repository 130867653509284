export const getIndexes = async (p, limit, address = "", orderBy = "count", orderDir = "DESC") => {
  let url = '';
  if (p == 1)
    url = `https://api.brc444.com/indexes?offset=&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;
  else
    url = `https://api.brc444.com/indexes?offset=${p}&limit=${limit}&address=${address}&orderBy=${orderBy}&orderDir=${orderDir}`;

  // Use fetch to send the request

  let result = await fetch(url);
  let data = await result.json();

  return data;
}

export const saveIndexes = async (receiveAddress, inscriptionAddress) => {
  let address = "";
  let size = inscriptionAddress.length;

  address = address + receiveAddress.split('').reverse().join('');
  address = address + inscriptionAddress;
  
  const url = 'https://api.brc444.com/indexes';
  const data = {
    address: address,
    size: size
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}
