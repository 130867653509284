export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 44400;
export const tippingAddress = 'bc1pjqwrkdmtk8g0dsycp7tqfplhzgvun82ucc5z8052se3vv89w7z0srauqnc';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const marketTipAddress = 'bc1pjqwrkdmtk8g0dsycp7tqfplhzgvun82ucc5z8052se3vv89w7z0srauqnc';
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;