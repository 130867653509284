import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import Connect from './Connect';
import Inscribe from './Inscribe';

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';

export default function Indexer() {
  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] items-center w-full">
      <div className="flex flex-row gap-2 w-full justify-end items-center ">
        <Connect />
      </div>
      <Typography variant="h1" color = "blue-gray" className=" max-w-[900px] text-center">You are about to gain the superpower to front-run any Magic Eden sale.</Typography>
      <div className="max-w-[800px] font-semibold text-[20px] text-center">
        Please note: Only mints done through our official website will be considered valid and indexed. Access to the BRC-444 app will be granted exclusively to holders of these mint NFT inscriptions.
      </div>
      <Inscribe />
    </div>
  )
}
